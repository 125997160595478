import PropTypes from "prop-types";
import React from "react";
import { withTheme } from "styled-components";

import Main from "../Router";

function WhiteLabel(props) {
	const { theme } = props;

	if (!theme) {
		return <>Theme file invalid!</>;
	}

	return <Main {...props} />;
}

export default withTheme(WhiteLabel);

WhiteLabel.propTypes = {
	storeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	theme: PropTypes.shape({}).isRequired,
};
