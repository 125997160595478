import PropTypes from "prop-types";
import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import { SPLASH_HOME } from "../config";

import useUTMTracker from "./hooks/useUTMTracker";

const Store = React.lazy(() => import("./store/Store"));
const Cart = React.lazy(() => import("./cart/Cart"));
const StoresList = React.lazy(() => import("./stores-list/StoresList"));
const Addresses = React.lazy(() => import("./addresses/Addresses/Addresses"));
const NewAddress = React.lazy(() =>
	import("./addresses/NewAddress/NewAddress")
);
const Receipt = React.lazy(() => import("./receipt/Receipt"));
const MyCards = React.lazy(() => import("./my-cards/MyCards"));
const NewCard = React.lazy(() => import("./cards/NewCard/NewCard"));
const StoreInfo = React.lazy(() => import("./store-info/StoreInfo"));
const NoStore = React.lazy(() => import("./no-store/NoStore"));
const SignIn = React.lazy(() => import("./sign-in/SignIn"));
const SignUp = React.lazy(() => import("./sign-up/SignUp"));
const PlacedOrders = React.lazy(() => import("./placed-orders/PlacedOrders"));
const OrderConfirmation = React.lazy(() =>
	import("./order-confirmation/OrderConfirmation")
);
const Coupons = React.lazy(() => import("./coupons/Coupons"));
const ContactUs = React.lazy(() => import("./contact-us/ContactUs"));
const Welcome = React.lazy(() => import("./welcome/Welcome/Welcome"));

function Main(props) {
	useUTMTracker();

	return (
		<Suspense fallback={<div>Carregando...</div>}>
			<Switch>
				<Route
					path="/store/:productURL"
					component={(routeProps) => <Store {...props} {...routeProps} />}
				/>
				<Route
					path="/store"
					component={(routeProps) => <Store {...props} {...routeProps} />}
				/>
				<Route
					path="/cart"
					component={(routeProps) => <Cart {...props} {...routeProps} />}
				/>
				<Route
					path="/stores-list"
					component={(routeProps) => <StoresList {...props} {...routeProps} />}
				/>
				<Route
					path="/addresses"
					component={(routeProps) => <Addresses {...props} {...routeProps} />}
				/>
				<Route
					path="/new-address"
					component={(routeProps) => <NewAddress {...props} {...routeProps} />}
				/>
				<Route
					path="/receipt"
					component={(routeProps) => <Receipt {...props} {...routeProps} />}
				/>
				<Route
					path="/cards"
					component={(routeProps) => (
						<MyCards payment={true} {...props} {...routeProps} />
					)}
				/>
				<Route
					path="/my-cards"
					component={(routeProps) => <MyCards {...props} {...routeProps} />}
				/>
				<Route
					path="/new-card"
					component={(routeProps) => <NewCard {...props} {...routeProps} />}
				/>
				<Route
					path="/store-info"
					component={(routeProps) => <StoreInfo {...props} {...routeProps} />}
				/>
				<Route
					path="/no-store"
					component={(routeProps) => <NoStore {...props} {...routeProps} />}
				/>
				<Route
					path="/login"
					component={(routeProps) => <SignIn {...props} {...routeProps} />}
				/>
				<Route
					path="/sign-up"
					component={(routeProps) => <SignUp {...props} {...routeProps} />}
				/>
				<Route
					path="/placed-orders/:orderId"
					component={(routeProps) => (
						<PlacedOrders {...props} {...routeProps} />
					)}
				/>
				<Route
					path="/placed-orders"
					component={(routeProps) => (
						<PlacedOrders {...props} {...routeProps} />
					)}
				/>
				<Route
					path="/order-confirmation"
					component={(routeProps) => (
						<OrderConfirmation {...props} {...routeProps} />
					)}
				/>
				<Route
					path="/coupons/:couponId"
					component={(routeProps) => <Coupons {...props} {...routeProps} />}
				/>
				<Route
					path="/coupons"
					component={(routeProps) => <Coupons {...props} {...routeProps} />}
				/>
				<Route
					path="/contact-us"
					component={(routeProps) => <ContactUs {...props} {...routeProps} />}
				/>
				<Route
					exact
					path="/:id?"
					component={(routeProps) =>
						SPLASH_HOME === "true" ? (
							<Welcome {...props} {...routeProps} />
						) : (
							<Store {...props} {...routeProps} />
						)
					}
				/>
			</Switch>
		</Suspense>
	);
}

export default Main;

Main.propTypes = {
	storeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	theme: PropTypes.shape({}).isRequired,
};
