import React, { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import * as Sentry from "@sentry/react";
import PropTypes from "prop-types";
import SnackbarProvider from "react-simple-snackbar";

import { GOOGLE_CLIENT_ID, THEME } from "../../config";

import GlobalStyle from "./AppGlobalStyle";
import WhiteLabel from "../white-label";
import history from "../../history";
import "animate.css";
import { GoogleOAuthProvider } from "@react-oauth/google";

function App({ store }) {
	const [storeId, setStoreId] = useState("");
	const [counterOnly, setCounterOnly] = useState("");
	const [urlAddress, setUrlAddress] = useState(null);

	useEffect(() => {
		const pathnames = window.location.pathname.split("/");
		const pathModality = window.location.href.split("?balcao=");
		const localStorageStore = JSON.parse(window.localStorage.getItem("main"));

		if (
			!pathnames[1] &&
			localStorageStore &&
			localStorageStore.store &&
			localStorageStore.store.id
		) {
			setStoreId(localStorageStore.store.id);
		} else if (pathnames[1] && pathnames[1] === "menu") {
			window.location = "/";
		} else if (pathnames[1]) {
			setStoreId(pathnames[1]);
		}

		if (pathModality && pathModality[1] && pathModality[1] === "1") {
			setCounterOnly(true);
		}

		return () => {};
	}, [setCounterOnly, setStoreId]);

	useEffect(() => {
		const pathAddress = window.location.href.split("address?");
		const URLParamsAddress = pathAddress && pathAddress[1];

		if (pathAddress && URLParamsAddress) {
			let pairs = URLParamsAddress.split("&");
			let result = {};
			pairs.forEach(function (p) {
				let pair = p.split("=");
				let key = pair[0];
				let value = decodeURIComponent(pair[1] || "");

				if (result[key]) {
					if (
						Object.prototype.toString.call(result[key]) === "[object Array]"
					) {
						result[key].push(value);
					} else {
						result[key] = [result[key], value];
					}
				} else {
					result[key] = value;
				}
			});
			setUrlAddress(JSON.parse(JSON.stringify(result)));
		}
	}, []);

	return (
		<GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
			<SnackbarProvider>
				<Router history={history} basePath={window.location.pathname}>
					<ThemeProvider theme={THEME}>
						<Provider store={store}>
							<GlobalStyle />
							<WhiteLabel
								storeId={storeId}
								modality={counterOnly}
								urlAddress={urlAddress}
								setUrlAddress={setUrlAddress}
							/>
						</Provider>
					</ThemeProvider>
				</Router>
			</SnackbarProvider>
		</GoogleOAuthProvider>
	);
}

App.propTypes = {
	store: PropTypes.shape({}),
};

export default Sentry.withProfiler(App);
